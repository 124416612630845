/* eslint-disable no-console */
import { Button, Container } from 'react-bootstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TabView, { TabViewItem } from 'components/TabView/TabView';
import CropsList from 'components/CropLists/CropsList';
import useSettings from 'contexts/SettingsContext';
import { useUserContext } from 'contexts/Users';
import Switch from 'components/Buttons/Switch';
import { ClusterDataTO, JobTypeTO, PointTO } from 'api/api.types';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import { HttpStatus } from 'enums';
import { assignCluster, ClusterAction, getClusterAction } from 'api/dataLayer';
import { cropsPlural, nextId } from 'utils';
import ConfirmModal from 'modals/ConfirmModal';
import { reorderPoints } from 'api/crops';
import CropActionsMenu, { MarkOptions } from './CropActionsMenu';
import CropView from './CropView';
import CropShelfView from './CropSelfView';
import useJobTypes from '../../../helpers/useJobTypes';
import StationControlPanel from '../../../components/StationControlPanel/StationControlPanel';

export type Point = PointTO & {
  clusterId: string;
  sortNumber: number;
};

export type Cluster = {
  id: string;
  name: string;
};

export const MAIN_CLUSTER_ID = '0';
const MAIN_CLUSTER_NAME = 'Main';
const DEFAULT_CLUSTER = { id: MAIN_CLUSTER_ID, name: MAIN_CLUSTER_NAME };

const ERROR_MESSAGE = 'Sorry something went wrong';

const CleaningStation = (): JSX.Element | undefined => {
  const [jobTypes, setJobTypes] = useState<JobTypeTO[]>([]);
  const [selectedJobType, setSelectedJobType] = useState<JobTypeTO | null>(
    null,
  );
  const [clusterData, setClusterData] = useState<ClusterDataTO | null>(null);
  const [clusters, setClusters] = useState<Cluster[]>([DEFAULT_CLUSTER]);
  const [selectedClusterId, setSelectedClusterId] =
    useState<string>(MAIN_CLUSTER_ID);
  const [crops, setCrops] = useState<Point[]>([]);
  const [selectedCrops, setSelectedCrops] = useState<{ [key: string]: any }>(
    {},
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRejected, setIsRejected] = useState(false);
  const [cropViewId, setCropViewId] = useState<string | undefined>();
  const [shelfViewCropId, setShelfViewCropId] = useState<string | null>(null);
  const { isAutoLoadOn, setAutoLoad, isLabelerMode, setLabelerMode } =
    useSettings();
  const { isLabeler, isExternal, assignJobTypes } = useUserContext();
  const { handleJobTypeChange } = useBatchLabelingContext();
  const [showRemoveClusterModal, setShowRemoveClusterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastRejectComment, setLastRejectComment] = useState('');
  const [rejectListExpanded, setRejectListExpanded] = useState(false);
  const [nextDraftClusterNumber, setNextDraftClusterNumber] = useState(1);

  const clearClusters = useCallback(() => {
    setClusters([DEFAULT_CLUSTER]);
    setNextDraftClusterNumber(1);
    setSelectedClusterId(MAIN_CLUSTER_ID);
  }, []);

  const clearData = useCallback(() => {
    setClusterData(null);
    // setSelectedJobType(null);
    setCrops([]);
    setSelectedCrops({});
    clearClusters();
    setIsRejected(false);
    setRejectListExpanded(false);
    setLastRejectComment('');
    setErrorMessage('');
  }, [clearClusters]);

  const assignLabeler = useCallback(
    async (jobType: JobTypeTO | null) => {
      if (!jobType) {
        setLoading(false);
        return;
      }
      setLoading(true);
      setSelectedJobType(jobType);
      setIsRejected(false);
      setRejectListExpanded(false);
      setLastRejectComment('');
      setErrorMessage('');

      try {
        const { status, data } = await assignCluster(
          isLabeler || isLabelerMode,
          jobType.project,
        );
        switch (status) {
          case HttpStatus.SUCCESS:
            const isNewCluster = clusterData?.id !== data.id;
            if (isNewCluster) {
              setSelectedJobType(jobType);
              setSelectedCrops({});
              clearClusters();
            }
            const clustersToKeep: any = {};
            setCrops(
              data.points.map((point, index) => {
                const prevCrop = isNewCluster
                  ? undefined
                  : crops.find((c) => c.id === point.id);
                if (prevCrop?.clusterId)
                  clustersToKeep[prevCrop?.clusterId] = true;
                return {
                  ...point,
                  clusterId: prevCrop?.clusterId ?? MAIN_CLUSTER_ID,
                  sortNumber: prevCrop?.sortNumber ?? index, // TODO: is there a better way to keep crops sorted? Keeped this for same UX as on prev UI
                };
              }),
            );
            if (!isNewCluster) {
              setClusters(
                clusters.filter(
                  ({ id }) => id === MAIN_CLUSTER_ID || clustersToKeep[id],
                ),
              );
              if (!clustersToKeep[selectedClusterId])
                setSelectedClusterId(MAIN_CLUSTER_ID);
            }

            setIsRejected(data.reject_flag);
            setClusterData(data);
            break;
          case HttpStatus.NO_CONTENT:
            clearData();
            setErrorMessage('There are no clusters to proceed');
            break;
          default:
            clearData();
            setErrorMessage(data.detail || '');
            break;
        }
      } catch (error) {
        console.error('Error assigning user:', error);
      } finally {
        setLoading(false);
      }
    },
    [
      clearData,
      clusterData?.id,
      clusters,
      crops,
      isLabeler,
      isLabelerMode,
      clearClusters,
      selectedClusterId,
    ],
  );

  useJobTypes(setLoading, setJobTypes);

  // Auto setting job type for labeler
  useEffect(() => {
    if (!assignJobTypes.length || !selectedJobType) return;
    if (selectedJobType?.id === assignJobTypes[0]) return;
    const assignedJobType = jobTypes.find(
      (jobType) => jobType.id === assignJobTypes[0],
    );
    if (!assignedJobType) return;
    setSelectedJobType(assignedJobType);
    assignLabeler(assignedJobType);
  }, [
    assignJobTypes,
    assignLabeler,
    jobTypes,
    selectedJobType,
    selectedJobType?.id,
  ]);

  const refreshData = async () => {
    if (!isAutoLoadOn) {
      clearData();
      setLoading(false);
      return;
    }
    await assignLabeler(selectedJobType);
    setSelectedCrops({});
  };

  // TODO: TS me?
  const checkResponse = async (response: any, keepClusterData = false) => {
    if (response.status === 200) {
      if (!keepClusterData) await refreshData();
    } else {
      return await response
        .json()
        .then((data: any) => setErrorMessage(data.detail || ERROR_MESSAGE))
        .catch(() => {
          setErrorMessage(ERROR_MESSAGE);
          return Promise.reject('error');
        });
    }
  };

  const clusterCrops = useMemo(
    () =>
      Object.fromEntries(
        clusters.map((cluster) => [
          cluster.id,
          crops
            .filter((crop) => crop.clusterId === cluster.id)
            .sort((a, b) => a.sortNumber - b.sortNumber),
        ]),
      ),
    [clusters, crops],
  );

  const selectedClusterSelectedCrops: Point[] = useMemo(
    () =>
      (clusterCrops[selectedClusterId] ?? []).filter(
        ({ id }) => selectedCrops[id],
      ),
    [clusterCrops, selectedClusterId, selectedCrops],
  );

  const disableCropsMoving =
    selectedClusterId === MAIN_CLUSTER_ID &&
    selectedClusterSelectedCrops.length &&
    selectedClusterSelectedCrops.length ===
      clusterCrops[MAIN_CLUSTER_ID].length;

  const markOptions: MarkOptions = useMemo(
    () =>
      isLabeler || isLabelerMode
        ? null
        : {
            canMarkForRemove: !!selectedClusterSelectedCrops.find(
              ({ marked_for_action }) =>
                !marked_for_action || marked_for_action === 'to_move',
            ),
            canMarkForMove: !!selectedClusterSelectedCrops.find(
              ({ marked_for_action }) =>
                !marked_for_action || marked_for_action === 'to_remove',
            ),
            canClearMarks: !!selectedClusterSelectedCrops.find(
              ({ marked_for_action }) => marked_for_action,
            ),
          },
    [isLabeler, isLabelerMode, selectedClusterSelectedCrops],
  );

  const hasMarkedCrops: boolean = useMemo(
    () => !!crops.find(({ marked_for_action }) => marked_for_action),
    [crops],
  );

  const onSelectJobType = (jobType: JobTypeTO) => {
    assignLabeler(jobType);
    handleJobTypeChange(jobType);
  };

  const onSelectLabelerMode = (mode: boolean) => {
    setLabelerMode(mode);
    setSelectedJobType(null);
    clearData();
  };

  const onSelectCrop = (cropId: string) => {
    if (selectedCrops[cropId]) {
      const updSelectedCrops = { ...selectedCrops };
      delete updSelectedCrops[cropId];
      setSelectedCrops(updSelectedCrops);
    } else {
      setSelectedCrops({ ...selectedCrops, [cropId]: true });
    }
  };

  const onUpdateSelections = (type: 'allBelow' | 'unselectAll' | 'invert') => {
    const updSelectedCrops = { ...selectedCrops };
    switch (type) {
      case 'allBelow':
        if (!selectedClusterSelectedCrops.length) return;
        const maxSelectedSortNumber =
          selectedClusterSelectedCrops[selectedClusterSelectedCrops.length - 1]
            .sortNumber;
        (clusterCrops[selectedClusterId] ?? [])
          .filter(({ sortNumber }) => sortNumber > maxSelectedSortNumber)
          .forEach(({ id }) => {
            updSelectedCrops[id] = true;
          });
        break;
      case 'unselectAll':
        selectedClusterSelectedCrops.forEach(({ id }) => {
          delete updSelectedCrops[id];
        });
        break;
      case 'invert':
        (clusterCrops[selectedClusterId] ?? []).forEach(({ id }) => {
          if (selectedCrops[id]) delete updSelectedCrops[id];
          else updSelectedCrops[id] = true;
        });
        break;
    }
    setSelectedCrops(updSelectedCrops);
  };

  const onSortBySelected = async () => {
    setLoading(true);
    const cropForSort = crops
      .filter(
        ({ id, clusterId }) =>
          selectedCrops[id] && clusterId === MAIN_CLUSTER_ID,
      )
      .map(({ id }) => id);
    const props = {
      points: cropForSort,
    };
    const response = await reorderPoints(clusterData?.id, props);

    if (response.status === 200) {
      await response
        .json()
        .then((data) => {
          const cropSorter = data.point_ids;
          const selectedCropsLength = Object.keys(selectedCrops).length;
          setCrops(
            crops.map((crop) => {
              const updatedCrop = { ...crop };
              if (selectedCrops[crop.id]) {
                updatedCrop.sortNumber = cropForSort.indexOf(crop.id);
              } else if (cropSorter.includes(crop.id)) {
                updatedCrop.sortNumber =
                  selectedCropsLength + cropSorter.indexOf(crop.id);
              }
              return updatedCrop;
            }),
          );
        })
        .catch(() => setErrorMessage(ERROR_MESSAGE));
    } else {
      setErrorMessage(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const onMoveToCluster = (id: string, updatedClusters = clusters) => {
    const updatedCrops = crops.map((crop) =>
      crop.clusterId === selectedClusterId && selectedCrops[crop.id]
        ? { ...crop, clusterId: id }
        : crop,
    );
    setCrops(updatedCrops);
    const filteredClusters = updatedClusters.filter((cluster) =>
      updatedCrops.find(({ clusterId }) => cluster.id === clusterId),
    );
    setClusters(filteredClusters);
    if (!filteredClusters.find((cluster) => cluster.id === selectedClusterId)) {
      setSelectedClusterId(id);
    }
    onUpdateSelections('unselectAll');
  };

  const onMoveToNewCluster = (name: string) => {
    const newCluster: Cluster = { id: nextId().toString(), name };
    const updatedClusters = [...clusters, newCluster];
    onMoveToCluster(newCluster.id, updatedClusters);
    setNextDraftClusterNumber((prev) => prev + 1);
  };

  const performClusterAction = async (
    action: ClusterAction,
    comment?: string,
    cropsList?: Point[],
    keepClusterData: boolean = false,
  ) => {
    if (loading) return;
    setLoading(true);
    if (!clusterData) return;
    return await checkResponse(
      await getClusterAction(action, clusterData.id, {
        fix: !(isLabeler || isLabelerMode),
        comment,
        cropIds: (cropsList ?? selectedClusterSelectedCrops).map(
          ({ id }) => id,
        ),
        clusterCrops,
      }),
      keepClusterData,
    );
  };

  const onShowCropView = (cropId: string) => {
    setCropViewId(cropId);
  };

  const onCloseCropView = () => {
    setCropViewId(undefined);
  };

  const onShowShelfView = (cropId: string) => {
    setShelfViewCropId(cropId || null);
  };

  const onResumeWork = () => {
    setAutoLoad(true);
    if (!selectedJobType) return;
    assignLabeler(selectedJobType);
  };

  const onMarkForRemove = () => {
    performClusterAction(
      'markForRemove',
      undefined,
      selectedClusterSelectedCrops.filter(
        ({ marked_for_action }) =>
          !marked_for_action || marked_for_action === 'to_move',
      ),
    );
  };
  const onMarkForMove = () => {
    performClusterAction(
      'markForMove',
      undefined,
      selectedClusterSelectedCrops.filter(
        ({ marked_for_action }) =>
          !marked_for_action || marked_for_action === 'to_remove',
      ),
    );
  };
  const onClearMarks = () => {
    return performClusterAction(
      'clearMarks',
      undefined,
      selectedClusterSelectedCrops.filter(
        ({ marked_for_action }) => marked_for_action,
      ),
    );
  };

  const onAcceptWithMarks = async () => {
    try {
      await performClusterAction(
        'clearMarks',
        undefined,
        crops.filter(({ marked_for_action }) => marked_for_action),
        true,
      );
      await performClusterAction('accept');
      // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  // TODO: Why is this needed?
  if (isExternal) return;
  return (
    <Container fluid className="new-ui station-container">
      <StationControlPanel
        selectedJobType={selectedJobType}
        jobTypes={jobTypes}
        onSelectJobType={(jobType: JobTypeTO) => onSelectJobType(jobType)}
        onResumeWork={() => onResumeWork()}
        errorMessage={errorMessage}
        isRejected={isRejected}
        clusterData={clusterData}
        clusters={clusters}
        setShowRemoveClusterModal={setShowRemoveClusterModal}
        performClusterAction={performClusterAction}
        onAcceptWithMarks={onAcceptWithMarks}
        hasMarkedCrops={hasMarkedCrops}
        lastRejectComment={lastRejectComment}
        setLastRejectComment={setLastRejectComment}
        rejectListExpanded={rejectListExpanded}
        setRejectListExpanded={setRejectListExpanded}
      />

      <div className="body-box mt-n1">
        <TabView
          activeKey={selectedClusterId}
          extraContent={
            clusters.length > 1 ? (
              <Button
                variant="outline-secondary"
                onClick={() => performClusterAction('acceptAllNewClusters')}>
                Accept All New Clusters
              </Button>
            ) : null
          }
          onSelect={(key) => setSelectedClusterId(key || MAIN_CLUSTER_ID)}>
          {clusters.map(({ id, name }) => {
            const cropsCount = (clusterCrops[id] || []).length;
            return (
              <TabViewItem
                tabKey={id}
                label={`${name} (${cropsCount} ${cropsPlural(cropsCount)})`}
                key={id}>
                <CropsList
                  crops={clusterCrops[id] || []}
                  selectedCrops={selectedCrops}
                  onSelectCrop={onSelectCrop}
                  empty={!clusterData?.id}
                  paused={!isAutoLoadOn}
                  loading={loading}
                  sortEnabled={id === MAIN_CLUSTER_ID}
                  onUpdateSelections={onUpdateSelections}
                  onShowCropView={onShowCropView}
                  onShowShelfView={onShowShelfView}
                  onSortBySelected={onSortBySelected}
                />
              </TabViewItem>
            );
          })}
        </TabView>
      </div>
      <CropActionsMenu
        cropsCount={selectedClusterSelectedCrops.length}
        clusters={clusters}
        selectedClusterId={selectedClusterId}
        disableCropsMoving={!!disableCropsMoving}
        markOptions={markOptions}
        nextDraftClusterNumber={nextDraftClusterNumber}
        onMoveToNewCluster={onMoveToNewCluster}
        onMoveToCluster={onMoveToCluster}
        onRemoveCrops={() => performClusterAction('removeSelectedCrops')}
        onDeselectAll={() => onUpdateSelections('unselectAll')}
        onMarkForRemove={onMarkForRemove}
        onMarkForMove={onMarkForMove}
        onClearMarks={onClearMarks}
      />
      <div className="d-flex mb-2 justify-content-end labeler-switch">
        {!isLabeler && !cropViewId && (
          <Switch
            name="1"
            checked={isLabelerMode}
            setChecked={onSelectLabelerMode}
            label="Labeler View Mode"
          />
        )}
      </div>
      <CropView
        crops={clusterCrops[selectedClusterId]}
        currentCropId={cropViewId}
        selectedCrops={selectedCrops}
        onCloseCropView={onCloseCropView}
        onSelectCrop={onSelectCrop}
        onShowShelfView={onShowShelfView}
        shelfView={!!shelfViewCropId}
      />
      <CropShelfView
        cropId={shelfViewCropId}
        onClose={() => setShelfViewCropId(null)}
      />
      <ConfirmModal
        header="Remove Cluster"
        confrimLabel="Remove Cluster"
        cancelLablel="Cancel"
        show={showRemoveClusterModal}
        onCancel={() => setShowRemoveClusterModal(false)}
        onConfirm={() => {
          setShowRemoveClusterModal(false);
          performClusterAction('removeCluster');
        }}>
        Do you want to remove cluster?
      </ConfirmModal>
    </Container>
  );
};
CleaningStation.displayName = 'CleaningStation';

export default CleaningStation;
