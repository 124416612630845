import React, { useState } from 'react';
import './scss/switch-button.scss';

interface SwitchProps {
  onTurnOn: () => void;
  onTurnOff: () => void;
  title: string;
  checked?: boolean;
}
// This is new SwitchButton for new UI
const SwitchButton: React.FC<SwitchProps> = ({
  onTurnOn,
  onTurnOff,
  title,
  checked = false,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.checked;
    setIsChecked(checkedValue);

    if (checkedValue) {
      onTurnOn();
    } else {
      onTurnOff();
    }
  };

  return (
    <label htmlFor="customSwitch" className="custom-control-switch">
      <span className="custom-control-switch__input">
        <input
          type="checkbox"
          id="customSwitch"
          checked={isChecked}
          onChange={handleChange}
        />
      </span>
      <span className="custom-control-switch__title">{title}</span>
    </label>
  );
};

export default SwitchButton;
