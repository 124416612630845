import { HttpStatus } from 'enums';
import { memoize } from 'utils';
import { ClusterDataTO, LabelerTO, UserTO } from './api.types';
import {
  acceptCluster,
  assignUser,
  markCrops,
  moveToNewCluster,
  rejectCluster,
  rejectClusterWithComment,
  removeCluster,
  removeSelectedCrops,
  sendCluster,
  sendClusterWithComment,
} from './clusters';
import { MAIN_CLUSTER_ID, Point } from '../views/Clusters/Cleaning/Cleaning';
import { getLabeler } from './user';

const splitStatusData = async <T,>(response: Response) => {
  const { status } = response;
  // TODO: replace with null after typecasting 'as' fix.
  const data: T = status === HttpStatus.NO_CONTENT ? {} : await response.json();
  return { data, status };
};

export const assignCluster = async (
  labeler: boolean,
  project: string,
): Promise<{ data: ClusterDataTO; status: number }> => {
  const userType = labeler ? 'labeler' : 'reviewer';
  const checkSubState = userType === 'reviewer' ? 'CLEANING_QC' : 'CLEANING';
  const response = await assignUser(userType, {
    project,
    substate: checkSubState,
  });
  return splitStatusData<ClusterDataTO>(response);
};

const moveMultipleClusters = async (
  id: string,
  params: {
    fix?: boolean;
    comment?: string;
    cropIds?: string[];
    clusterCrops: { [k: string]: Point[] };
  },
) => {
  return Promise.allSettled(
    Object.entries(params.clusterCrops)
      .filter(([clusterId]) => clusterId !== MAIN_CLUSTER_ID)
      .map(([_, crops]) =>
        moveToNewCluster(id, params?.fix ? { fix: true } : null, {
          crop_ids: crops.map((crop) => crop.id),
        }),
      ),
  ).then((responses) => {
    if (responses.find((r) => r.status === 'rejected'))
      return { json: () => Promise.reject('rejected') };
    const response: any = responses[0]; // TODO: fix typecasting 'as' in eslint
    return response.value;
  });
};

export type ClusterAction =
  | 'send'
  | 'sendClusterWithComment'
  | 'reject'
  | 'rejectClusterWithComment'
  | 'removeCluster'
  | 'removeSelectedCrops'
  | 'accept'
  | 'acceptAllNewClusters'
  | 'markForRemove'
  | 'markForMove'
  | 'clearMarks';

// TODO: type function return
export const getClusterAction = async (
  action: ClusterAction,
  id: string,
  params: {
    fix?: boolean;
    comment?: string;
    cropIds?: string[];
    clusterCrops: { [k: string]: Point[] };
  },
): Promise<any> => {
  switch (action) {
    case 'send':
      return sendCluster(id);
    case 'sendClusterWithComment':
      return sendClusterWithComment(id, { comment: params?.comment });
    case 'accept':
      return acceptCluster(id);
    case 'reject':
      return rejectCluster(id);
    case 'rejectClusterWithComment':
      return rejectClusterWithComment(id, { comment: params?.comment });
    case 'removeCluster':
      return removeCluster(id, params?.fix ? { fix: true } : undefined);
    case 'removeSelectedCrops':
      return removeSelectedCrops(id, params?.fix ? { fix: true } : undefined, {
        crop_ids: params?.cropIds,
      });
    case 'acceptAllNewClusters':
      return moveMultipleClusters(id, params);
    case 'markForRemove':
      return markCrops(
        id,
        { type: 'to_remove' },
        { crop_ids: params?.cropIds },
      );
    case 'markForMove':
      return markCrops(id, { type: 'to_move' }, { crop_ids: params?.cropIds });
    case 'clearMarks':
      return markCrops(id, undefined, { crop_ids: params?.cropIds });
  }
};

const fGetLabelerInfo = async (id: number): Promise<UserTO> => {
  const response = await getLabeler(id);

  if (response.status === HttpStatus.SUCCESS) {
    const data: LabelerTO = await response.json();
    return data.user;
  }
  return Promise.reject('not found');
};

/** Also works for reviewer */
export const getLabelerInfo = memoize(fGetLabelerInfo);
