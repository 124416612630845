// TODO: Looks like we have old TS parser. Update.
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Cookies } from 'react-cookie';

export const userIsLogged = (): boolean => {
  const cookies = new Cookies();
  return cookies.get('user') !== undefined;
};

export const getUserToken = (): string | undefined => {
  const user = new Cookies().get('user');
  if (user) {
    const { token } = user;
    return token;
  }
  window.location.pathname = '/login';
};

export const percentage = (partialValue: number, totalValue: number): number =>
  (100 * partialValue) / totalValue;

// TODO: type me
export const getRectagle = (labelId: string, photoObjects: any[]): any => {
  const result = photoObjects
    .filter((item) => item.description.properties.featurestore_id === labelId)
    .shift();
  return result ? result.rectangle : {};
};

let curId = 1;
// eslint-disable-next-line no-plusplus
export const nextId = (): number => curId++;

export const toPlural = (count: number, name: string): string =>
  count === 1 ? name : `${name}s`;

export const cropsPlural = (cropsCount: number): string =>
  toPlural(cropsCount, 'crop');

/** simple memo without cache clearing */
export const memoize = <T>(f: T): T => {
  const cache: any = {};
  // See comment at line 1
  // @ts-ignore
  const cached: T = (...args: any[]) => {
    const key = JSON.stringify(args);
    if (cache[key]) return cache[key];
    // @ts-ignore
    const res = f(...args);
    cache[key] = res;
    return res;
  };
  return cached;
};
