import { EmptyFolderIcon } from 'components/Icons';
import React, { FC } from 'react';

const NoCluster: FC<{ paused?: boolean }> = ({ paused }) => {
  return (
    <div className="empty-cluster">
      <EmptyFolderIcon />
      {paused ? (
        <>
          <div className="main-text">Paused</div>
          <div className="additional-text">
            Click &quot;Autoload&quot; to continue
          </div>
        </>
      ) : (
        <>
          <div className="main-text">No Clusters Found</div>
          <div className="additional-text">Select relevant job</div>
        </>
      )}
    </div>
  );
};
NoCluster.displayName = 'NoCluster';

export default NoCluster;
