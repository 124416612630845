import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';

// New UI modal
const ConfirmModal: FC<{
  show: boolean;
  header?: string;
  confrimLabel?: string;
  cancelLablel?: string;
  onConfirm: () => void;
  onCancel: () => void;
  disabled?: boolean;
}> = ({
  show,
  header,
  confrimLabel,
  cancelLablel,
  onConfirm,
  onCancel,
  disabled,
  children,
}) => {
  useHotkeys('enter', () => !disabled && onConfirm(), {
    enabled: show,
  });
  useHotkeys('ctrl+enter', () => !disabled && onConfirm(), {
    enabled: show,
    enableOnContentEditable: true,
    enableOnFormTags: true,
  });

  useHotkeys('escape', () => onCancel(), { enabled: show });
  return (
    <Modal
      className="modal-primary modal-new-ui"
      show={show}
      centered
      onHide={() => onCancel()}
      size="lg">
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Body>{children}</Modal.Body>
      <div className="modal-footer">
        <Button
          className="btn"
          type="button"
          variant="link"
          onClick={() => onCancel()}>
          {cancelLablel ?? 'Close'}
        </Button>
        <Button
          disabled={disabled}
          className="btn btn-fill"
          type="button"
          variant="link"
          onClick={() => onConfirm()}>
          {confrimLabel ?? 'Accept'}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
