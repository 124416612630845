import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import JobTypeDropdown from 'components/Buttons/JobTypeDropdown';
import { getJobTypes } from 'api/jobTypes';
import { useUserContext } from 'contexts/Users';
import UploadImage from './UploadImage';
import CropImage from './CropImage';
import SelectState from '../../components/Select/SelectState';

function ClusterFinder() {
  const { isLabeler, assignJobTypes, setAssignJobTypes } = useUserContext();
  const [jobTypes, setJobTypes] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState({});
  const [assignedJobType, setAssignedJobType] = useState({});
  const [results, setResults] = useState({});
  const [selectedState, setSelectedState] = useState([]);
  const [prevParams, setPrevParams] = useState({});
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        const response = await getJobTypes({ for_clustering: true, page_size: 999 });
        const data = await response.json();
        const jobTypeList = data.results;

        if (isLabeler) {
          setAssignJobTypes();
        }

        setJobTypes(jobTypeList);
      } catch (error) {
        console.error('Error fetching job types:', error);
      }
    };

    fetchJobTypes();
  }, [isLabeler]);

  useEffect(() => {
    if (!assignJobTypes.length || !isLabeler) return;
    const assignedJobTypes = jobTypes.filter((jobType) => jobType.id === assignJobTypes[0]);
    setAssignedJobType(assignedJobTypes[0]?.project);
  }, [assignJobTypes]);

  function handleResponce(res, params, formData) {
    setPrevParams(params);
    setFormData(formData);
    if (!res) return;
    setResults(res);
  }

  return (
    <div>
      <Row>
        <div className="col-6">
          <Card>
            <Card.Body>
              <Row>
                <Col className="mb-4 mx-n3">
                  {isLabeler ? (
                    <div className="text-center">Job Type: {assignJobTypes[0]}</div>
                  ) : (
                    <JobTypeDropdown
                      predefinedClass
                      selectedJobType={selectedJobType}
                      onClickJobType={(jobType) => setSelectedJobType(jobType)}
                      jobTypeList={jobTypes}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="d-flex mb-4">
                  <SelectState
                    selectedMultiState={selectedState}
                    setSelectedMultiState={setSelectedState}
                    isMulti
                    className="mr-3"
                  />
                </Col>
              </Row>
              <UploadImage
                selectedState={selectedState}
                handleResponce={(res, params, formData) => handleResponce(res, params, formData)}
                assignJobType={selectedJobType.project || assignedJobType}
              />
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header />
            <Card.Body>
              <CropImage
                items={results.results}
                previous={results.previous}
                next={results.next}
                count={results.count}
                prevParams={prevParams}
                formData={formData}
                isImageFinder
              />
            </Card.Body>
          </Card>
        </div>
      </Row>
    </div>
  );
}

export default ClusterFinder;
