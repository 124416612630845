// TODO: Fix key interactions
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import { Card, Button, Spinner } from 'react-bootstrap';
import useCropSize, { CropSize } from 'helpers/useCropSize';
import {
  CheckIcon,
  CheckSelectedIcon,
  MarkedForMoveIcon,
  MarkedForRemoveIcon,
  ShelfIcon,
  SizeLargeIcon,
  SizeMediumIcon,
  SizeSmallIcon,
  ZoomIcon,
} from 'components/Icons';
import classNames from 'classnames';
import { Point } from 'views/Clusters/Cleaning/Cleaning';
import NoCluster from './NoCluster';

// This is new CropsList for new UI

interface CropProps {
  cropSize: CropSize;
  crops: Point[];
  selectedCrops: { [key: string]: any };
  onSelectCrop: (cropId: string) => void;
  onShowCropView: (cropId: string) => void;
  onShowShelfView: (cropId: string) => void;
}

const Crops: FC<CropProps> = ({
  cropSize,
  crops,
  selectedCrops,
  onSelectCrop,
  onShowCropView,
  onShowShelfView,
}) => (
  <div className="crops-container">
    <div>
      {crops.map((crop) => {
        const selected = !!selectedCrops[crop.id];
        const removeFlag = crop.marked_for_action === 'to_remove';
        const moveFlag = crop.marked_for_action === 'to_move';
        return (
          <div
            className={classNames(
              'crop-container',
              { selected },
              { removeFlag },
              { moveFlag },
            )}
            key={crop.id}
            onClick={() => {
              onSelectCrop(crop.id);
            }}>
            <div
              className={classNames('crop', {
                small: cropSize === CropSize.Small,
                medium: cropSize === CropSize.Medium,
                large: cropSize === CropSize.Large,
              })}>
              <img src={crop.crop_path} alt="crop" />
            </div>
            <div
              className="crop-icon crop-icon-zoom"
              title="Zoom"
              onClick={(e) => {
                e.stopPropagation();
                onShowCropView(crop.id);
              }}>
              <ZoomIcon />
            </div>
            <div
              className="crop-icon crop-icon-shelf"
              title="Shelf"
              onClick={(e) => {
                e.stopPropagation();
                onShowShelfView(crop.id);
              }}>
              <ShelfIcon />
            </div>
            <div className="crop-icon crop-icon-check">
              {selected ? <CheckSelectedIcon /> : <CheckIcon />}
            </div>
            {crop.marked_for_action === 'to_remove' && (
              <div
                className="crop-icon crop-icon-mark"
                title="This crop should to be removed from the cluster">
                <MarkedForRemoveIcon />
              </div>
            )}
            {crop.marked_for_action === 'to_move' && (
              <div
                className="crop-icon crop-icon-mark"
                title="This crop should to be moved to another cluster">
                <MarkedForMoveIcon />
              </div>
            )}
          </div>
        );
      })}
    </div>
  </div>
);

Crops.displayName = 'Crop';

interface CropListProps {
  crops: Point[];
  selectedCrops: { [key: string]: any };
  onSelectCrop: (cropId: string) => void;
  empty: boolean;
  paused: boolean;
  loading: boolean;
  sortEnabled: boolean;
  onUpdateSelections: (type: 'allBelow' | 'unselectAll' | 'invert') => void;
  onShowCropView: (cropId: string) => void;
  onShowShelfView: (cropId: string) => void;
  onSortBySelected: () => void;
}

const CropsList: FC<CropListProps> = ({
  crops,
  selectedCrops,
  onSelectCrop,
  empty,
  paused,
  loading,
  sortEnabled,
  onUpdateSelections,
  onShowCropView,
  onShowShelfView,
  onSortBySelected,
}) => {
  const [cropSize, setCropSize] = useCropSize();

  return (
    <div>
      <Card>
        <Card.Body>
          <div className="topButton d-flex justify-content-between align-items-start pb-3">
            <div className="d-flex flex-wrap gap-8">
              <Button
                variant="outline-secondary"
                disabled={empty}
                onClick={() => onUpdateSelections('allBelow')}>
                Select All Below
              </Button>
              <Button
                variant="outline-secondary"
                disabled={empty}
                onClick={() => onUpdateSelections('unselectAll')}>
                Deselect All
              </Button>
              <Button
                variant="outline-secondary"
                disabled={empty}
                onClick={() => onUpdateSelections('invert')}>
                Invert Selection
              </Button>
              <Button
                variant="outline-secondary"
                disabled={!sortEnabled || !Object.values(selectedCrops).length}
                onClick={onSortBySelected}>
                Sort by selected
              </Button>
            </div>
            <div className="d-flex justify-content-end mt-1">
              <Button
                variant={
                  cropSize === CropSize.Large ? 'simple-highlight' : 'simple'
                }
                onClick={() => setCropSize(CropSize.Large)}>
                <SizeLargeIcon />
              </Button>
              <Button
                className="ml-1"
                variant={
                  cropSize === CropSize.Medium ? 'simple-highlight' : 'simple'
                }
                onClick={() => setCropSize(CropSize.Medium)}>
                <SizeMediumIcon />
              </Button>
              <Button
                className="ml-1"
                variant={
                  cropSize === CropSize.Small ? 'simple-highlight' : 'simple'
                }
                onClick={() => setCropSize(CropSize.Small)}>
                <SizeSmallIcon />
              </Button>
            </div>
          </div>
          {empty && !loading ? (
            <NoCluster paused={paused} />
          ) : (
            <Crops
              crops={crops}
              selectedCrops={selectedCrops}
              onSelectCrop={onSelectCrop}
              cropSize={cropSize}
              onShowCropView={onShowCropView}
              onShowShelfView={onShowShelfView}
            />
          )}
          {loading && (
            <div className="loader">
              <Spinner animation="border" />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
CropsList.displayName = 'CropsList';

export default CropsList;
