import React, { useState } from 'react';
import { Card, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PAGE_SIZE } from '../../../components/Paginators/ListPaginator';
import { getClusterSimilar } from '../../../api/clusters';
import SelectState from '../../../components/Select/SelectState';
import SpinnerButton from '../../../components/Buttons/SpinnerButton';
import CropImage from '../CropImage';

function ShowSimilarModal({ showSimilarState, setShowSimilarState }) {
  const { showModal, item } = showSimilarState;
  const [selectedState, setSelectedState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevParams, setPrevParams] = useState({});
  const [results, setResults] = useState({});

  const handleClusterFinder = () => {
    setIsLoading(true);
    setPrevParams({});
    setResults({});
    const selectedStateValue = selectedState.map((state) => state.value);
    const params = {
      project: item.project,
      blocked: false,
      page: 1,
      page_size: PAGE_SIZE
    };
    if (selectedStateValue.length > 0) params.state__in = selectedStateValue;

    getClusterSimilar(item.id, params).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        setPrevParams(params);
        setResults(data);
      }
      setIsLoading(false);
    });
  };

  const handleClose = () => {
    setSelectedState([]);
    setPrevParams({});
    setResults({});
    setShowSimilarState({
      showModal: false,
      item: undefined
    });
  };

  return (
    <Modal size="lg" show={showModal} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title as="h4" className="mt-0">
          Show similar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please choose state(s) and / or press "Show similar"
        <div className="d-flex justify-content-between align-items-center pt-2">
          <SelectState
            selectedMultiState={selectedState}
            setSelectedMultiState={setSelectedState}
            isMulti
            isDisabled={isLoading}
          />
          <div className="text-center">
            <SpinnerButton
              disabled={false}
              className="btn ml-2"
              onClick={handleClusterFinder}
              isLoading={isLoading}
              value="Show similar"
            />
          </div>
        </div>
        {isLoading && <Spinner size="md" animation="border" role="status" />}
        {!isLoading && results?.results && (
          <Card className="mt-3">
            <Card.Header />
            <Card.Body>
              <CropImage
                items={results.results}
                previous={results.previous}
                next={results.next}
                count={results.count}
                prevParams={prevParams}
                formData={item.id}
              />
            </Card.Body>
          </Card>
        )}
      </Modal.Body>
    </Modal>
  );
}

ShowSimilarModal.propTypes = {
  showSimilarState: PropTypes.object,
  setShowSimilarState: PropTypes.func
};

ShowSimilarModal.defaultProps = {
  showSimilarState: {}
};

export default ShowSimilarModal;
