import React, { useState, useEffect } from 'react';
import JobTypeDropdown from 'components/Buttons/JobTypeDropdown';
import { getJobTypes } from 'api/jobTypes';
import { getManage } from 'api/manageClusters';
import { getLabelers } from 'api/user';

import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import BoardSectionList from 'views/Clusters/partials/dnd/BoardSectionList';

function Manage() {
  const [items, setItems] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [assignedLabelers, setAssignedLabelers] = useState();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const [jobTypes, setJobTypes] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState();
  const onClickJobType = async (jobType) => {
    if (jobType === '') {
      setSelectedJobType(undefined);
      return;
    }
    const response = await getManage(jobType.id);
    const newAssignedLabelers = await response.json();
    setAssignedLabelers(newAssignedLabelers);
    setSelectedJobType(jobType);
  };

  const fetchLabelers = async () => {
    getLabelers().then(async (response) => {
      const data = await response.json();
      setItems(data.results);
      setLoaded(true);
    });
  };

  const fetchJobTypes = async () => {
    try {
      const response = await getJobTypes({ for_clustering: true, page_size: 999 });
      const data = await response.json();
      const jobTypeList = data.results;
      setJobTypes(jobTypeList);
    } catch (error) {
      console.error('Error fetching job types:', error);
    }
  };

  useEffect(() => {
    fetchJobTypes();
    fetchLabelers();
  }, []);

  const findContainer = (id) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
      }));
    }
  };
  return (
    loaded && (
      <section>
        <JobTypeDropdown
          predefinedClass
          selectedJobType={selectedJobType}
          onClickJobType={(jobType) => onClickJobType(jobType)}
          jobTypeList={jobTypes}
        />
        <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
          <div className="card-body">
            {selectedJobType?.id && (
              <BoardSectionList
                items={items}
                jobTypeId={selectedJobType?.id}
                assignedLabelers={assignedLabelers}
              />
            )}
          </div>
        </DndContext>
      </section>
    )
  );
}

export default Manage;
