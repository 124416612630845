import { UNASSIGNED } from 'views/Clusters/partials/dnd/utils/constants';
import { getDataWithToken, putDataAuthorized } from './base';

export const getManage = (jobTypeId) => {
  const pathname = `clusters/manage_with_job_type/${jobTypeId}`;
  return getDataWithToken(pathname);
};

export const setManage = (job_type_id, props) => {
  let pathname = `clusters/manage_with_job_type/${job_type_id}?labeler_id=${props.labeler_id}`;
  if (props.assigned_station !== UNASSIGNED) {
    pathname = `${pathname}&assigned_station=${props.assigned_station}`;
  }

  return putDataAuthorized(pathname);
};
